<template>
    <layout>
        <page-header :title="title" :items="items" />

        <awesome-table
            :labels="labels"
            :items="timelines"
            :options="{ sort: true }"
            :pagination="pagination"
            @add-button-click="$router.push('/timelines/new')"
            @row-click="$router.push(`/timelines/${$event.id}/edit`)"
            @mouse-wheel-click="$openNewTab(`/timelines/${$event.id}/edit`)"
            @pagination-change="onPaginationChange"
        >
            <template #[`items.is_remake`]="{ item }">
                <span
                    v-if="item.is_remake"
                    class="badge bg-soft-success text-success"
                >
                    Yes
                </span>
                <span v-else class="badge bg-soft-danger text-danger">
                    No
                </span>
            </template>
            <template #[`items.is_oto_discount_accepted`]="{ item }">
                <span
                    v-if="item.is_oto_discount_accepted"
                    class="badge bg-soft-success text-success"
                >
                    Yes
                </span>
                <span v-else class="badge bg-soft-danger text-danger">
                    No
                </span>
            </template>
            <template #[`items.actions`]="{ item }">
                <a
                    href="#"
                    class="action-icon"
                    @click.prevent.stop="removeTimeline(item.id)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </template>
        </awesome-table>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Timelines',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'date',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Timelines',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Timelines',
                    active: true
                }
            ],
            timelines: [],
            labels: [
                'date',
                'name',
                'is_remake',
                'is_oto_discount_accepted',
                {
                    value: 'created_at',
                    filter: 'formatDate'
                },
                { value: 'actions', sortable: false }
            ],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
        };
    },

    mounted() {
        this.fetchTimelines();
    },

    methods: {
        ...mapActions({
            getTimelines: 'timelines/index',
            deleteTimeline: 'timelines/destroy'
        }),

        async fetchTimelines() {
            try {
                const { rows, count } = await this.getTimelines(
                    this.pagination
                );

                this.timelines = [...rows];
                this.pages = Math.ceil(count / this.perPage);
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async removeTimeline(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this timeline?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteTimeline(id);

                this.timelines = this.timelines.filter(item => item.id !== id);

                this.$toaster('Timeline has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchTimelines();
        }
    }
};
</script>
